import {siteConfigStore} from '$stores/siteConfig';
import {getStoreUser} from '$stores/user';
import {datadogLogs} from '@datadog/browser-logs';
import {get} from 'svelte/store';

export function logError(error: unknown, customMessage?: string) {
	if (import.meta.env.MODE === 'production') {
		const siteConfig = get(siteConfigStore);
		datadogLogs.logger.error(
			customMessage ?? 'Error',
			{
				user: getUserData(),
				instance: siteConfig?.externalId
			},
			error as Error
		);
	} else {
		console.error(error);
	}
}

export function getUserData() {
	const user = getStoreUser();
	let userData = {};

	if (user) {
		const {id, mail, preferredLang, userGroups, username} = user;
		userData = {
			id,
			mail,
			preferredLang,
			userGroups: userGroups?.map((group) => group.group.name).join(', '),
			username
		};
	}

	return userData;
}

export function logInfo(info: string) {
	if (import.meta.env.MODE === 'production') {
		const siteConfig = get(siteConfigStore);
		datadogLogs.logger.info(info, {
			user: getUserData(),
			instance: siteConfig?.externalId
		});
	} else {
		console.info(info);
	}
}

export function logWarning(error: unknown, customMessage?: string) {
	if (import.meta.env.MODE === 'production') {
		const siteConfig = get(siteConfigStore);
		datadogLogs.logger.warn(
			customMessage ?? 'Warning',
			{
				user: getUserData(),
				instance: siteConfig?.externalId
			},
			error as Error
		);
	} else {
		console.warn(error);
	}
}
