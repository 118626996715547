import type {Company} from '$types/company';
import type {Contact} from '$types/contact';
import type ApiClient from '@blg/jsapilib';
import uniq from 'lodash/uniq';
import {get, type Writable, writable} from 'svelte/store';

export type LegalEntities = {
	ids: number[];
};

export type UserGroup = {
	group: {
		id: number;
		name: string;
		rights: null;
		interfaceRights: null;
		title: string;
		fieldConstraints: null;
		active: boolean;
		accessAllDataContext: boolean;
		type: string;
	};
};

export type User = {
	id: number;
	contact: Contact;
	username: string;
	active: boolean;
	mail: string;
	timezone: string;
	lastAccessDate: Date;
	userGroups: UserGroup[];
	company: Company | undefined;
	rightCompanies: {
		company: Company;
	}[];
	profilePicture?: string;
	lastPasswordResetRequestDate: Date;
	loginRateLimitResetDate: Date;
	preferredLang: string;
	//eslint-disable-next-line
	createdActivities: Record<string, any>[];
	editedMails: Record<string, any>[];
	smtpAccounts: SmtpAccount[];
	//eslint-disable-next-line
	cachedData: Record<string, any>;
};

export type SmtpAccount = {
	id: number;
	name?: string | null;
	senderMail?: string;
	senderLabel?: string;
	username?: string;
	password?: string;
	host?: string;
	port?: number;
	encryptionType?: string;
	active?: boolean;
	signature?: string | null;
	createDate?: Date;
};

export type CmsContact = {
	id: number;
	name: string;
	siteName: string;
	lang: string;
	category: string;
	title: string;
	contact: Partial<Contact>;
};

export type UserInfos = {
	user: User | null;
	apiClient: ApiClient | null;
	selectedCompany?: Company | null;
	lastUpdate: number | null;
};

interface UserStore extends Writable<UserInfos> {
	setUser: (
		apiClient: UserInfos['apiClient'],
		user: UserInfos['user']
	) => void;
	getCompaniesIds: () => Company['id'][];
}

export const USER_DEFAULT: UserInfos = {
	user: null,
	lastUpdate: null,
	apiClient: null,
	selectedCompany: null
};

function getLocalStorageCompany(): Company | null {
	const company: string | null = localStorage.getItem('selectedCompany');
	const companyParsed: Company | null = company ? JSON.parse(company) : null;
	return companyParsed;
}

function createUserStore(): UserStore {
	const userStore = writable(USER_DEFAULT);

	return {
		...userStore,
		setUser: (apiClient, user) => {
			const selectedCompany = getLocalStorageCompany();

			userStore.set({
				apiClient,
				user,
				lastUpdate: Date.now(),
				selectedCompany
			});
		},
		getCompaniesIds: () => {
			const user = getStoreUser();
			const companyId = user?.company?.id;

			const rightCompaniesIds =
				user?.rightCompanies.map((c) => c.company.id) || [];

			return companyId
				? uniq([companyId, ...rightCompaniesIds])
				: rightCompaniesIds;
		}
	};
}

export function getApiClient() {
	return get(userStore).apiClient;
}

export function getStoreUser() {
	return get(userStore).user;
}

export const userStore = createUserStore();
